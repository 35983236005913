<template>
    <f7-page name="singout">
        <f7-navbar title="Darse de baja" back-link="Back"></f7-navbar>
        <transition name="fade">
            <div v-if="performingRequest" class="loading">
                <f7-preloader color="green"></f7-preloader>
            </div>
        </transition>
        <f7-block>
            <div class="singout-container content-block" inset>
                <f7-list form autocomplete="off" class="elevation-3 bg-box">
                    <input autocomplete="false" name="hidden" type="text" style="display:none;" />
                    <f7-login-screen-title>Borrar cuenta y datos personales</f7-login-screen-title>
                    <p class="text-align-center">Lamentamos verte partir. Por favor, confirma que deseas eliminar tu
                        cuenta y todos tus datos personales.
                        Esta acción no se puede deshacer.</p>
                    <p v-if="errors.length" class="text-align-center warning">
                        <span v-if="errors.length > 1">Por favor corriga los siguientes errores:</span>
                        <span v-else>Por favor corriga el siguiente error:</span>
                    <ul class="mt-2">
                        <li v-for="error in errors" :key="error">
                            {{ error }}
                        </li>
                    </ul>
                    </p>
                    <f7-list-input type="text" name="email" autocomplete="off" placeholder="Su correo"
                        class="alf-input" :value="email" @input="email = $event.target.value"></f7-list-input>
                    <f7-list-input :type="type" name="password" autocomplete="off" placeholder="Su contraseña"
                        :value="password" @input="password = $event.target.value">
                        <div @click="showPassword" slot="content-end">
                            <f7-icon :f7="passwordIcon"></f7-icon>
                        </div>
                    </f7-list-input>
                    <f7-list>
                        <f7-row tag="p">
                            <f7-button class="col" round fill @click="userSingout">Eliminar Usuario</f7-button>
                        </f7-row>
                    </f7-list>
                </f7-list>
        
            </div>
        </f7-block>
    </f7-page>
</template>
<script>
import axios from "axios";
import VueAxios from "vue-axios";
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState([
            "userProfile",
            "performingRequest",
        ]),
    },
    data() {
        return {
            // Login screen data
            email: "",
            password: "",
            type: "password",
            passwordIcon: "eye",
            showLoginForm: true,
            errorMsg: "",
            errors: []
        };
    },
    methods: {
        toggleForm() {
            this.errors = []
            this.errorMsg = "";
            this.showLoginForm = !this.showLoginForm;
        },
        togglePasswordReset() {
            this.errors = []
            if (this.showForgotPassword) {
                this.showLoginForm = true;
                this.showForgotPassword = false;
                this.passwordResetSuccess = false;
            } else {
                this.showLoginForm = false;
                this.showForgotPassword = true;
            }
        },
        async userSingout(e) {
            // Validar Formulario
            this.errors = []
            var that = this;
            if (!this.email) {
                this.errors.push('El correo es obligatorio.')
            } else if (!this.validEmail(this.email)) {
                this.errors.push('El email es incorrecto.')
            }
            if (!this.password) {
                this.errors.push('La clave es obligatoria.')
            }

            if (!this.errors.length) {
                const url = 'https://reumapp.alfamediaweb.com/auth.php?callback=?'
                const data = new FormData()
                data.append('email', this.email)
                data.append('password', this.password)
                data.append('signout', '')

                this.$store.dispatch("performRequest");
                try {
                    const res = await axios.post(url, data)
                    if (res.data === 'success') {
                        this.$cookies.remove("user");
                        this.$store.dispatch("performRequest");
                        this.$f7.views.main.router.navigate("/login/");
                        return true
                    } else {
                        this.$store.dispatch("performRequest");
                        this.errors.push(res.data)
                    }
                } catch (err) {
                    this.errors = []
                    this.errors.push('Error de conexión.')
                    this.$store.dispatch("performRequest");
                }
            }
            e.preventDefault()
        },


        showPassword() {
            this.errors = []
            if (this.type === 'password') {
                this.type = 'text'
                this.passwordIcon = 'eye_slash_fill'
            } else {
                this.type = 'password'
                this.passwordIcon = 'eye_fill'
            }
        },
        validEmail: function (email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        validPassword: function (pass) {
            const re = /[0-9a-zA-Z]{6,}/
            return re.test(pass)
        }
    }
}
</script>

<style scoped>
.w70 {
    width: 70%;
}

.bg-box {
    background-color: rgba(53, 151, 70, 0.05);
    padding: 15px 30px;
}

.warning {
    background-color: rgba(255, 255, 215, 0.25);
    padding: 15px 30px;
}
</style>