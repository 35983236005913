<template>
  <f7-page name="catalog" >
    <f7-navbar title="Archivo de pacientes" back-link="Back"></f7-navbar>
    <div class="container">
      <f7-block v-if="patients.length > 0">
        <f7-block-title medium>Seleccione el paciente</f7-block-title>
        <f7-list >
          <f7-list-item
            v-for="(patient, index) in patients"
            :key="index"
            :title="patient.nombre"
            :link="`/patient/${patient.id}/`"
          ></f7-list-item>
        </f7-list>
      </f7-block>
      <f7-block v-else>
         <f7-block-title medium>Archivo de pacientes vacio</f7-block-title>
        <p>Aun no se le ha asignado ningún paciente a esta cuenta</p>
      </f7-block>  
    </div>
  </f7-page>
</template>
<script>
  import axios from "axios";
  import VueAxios from "vue-axios";
  import { mapState } from "vuex";
  
  export default {
    computed: {
			...mapState([
				"userProfile",
        "performingRequest",
			]),
		},
    data: function () {
      return {
        patients: []
      };
    },
    mounted(){
      this.getTransitionInfo()
    },
    methods: {
        async getTransitionInfo() {
        var url = "https://reumappdata.alfamediaweb.com/reumappdata/items/transicion?filter[email][eq]=" + this.userProfile.email;
        var dataString = '';
        try {
          const res = await axios.get(url, dataString)
          this.patients = eval(res.data.data)
          console.log(res.data.data.length) 
        } catch (err) {
          console.log(err)
        }
       } 
    }
  };
</script>
