import LoginPage from '../pages/login.vue';
import HomePage from '../pages/home.vue';
import TerminosPage from '../pages/terminos.vue';
import FormPage from '../pages/form.vue';
import CatalogPage from '../pages/catalog.vue';
import PatientPage from '../pages/patient.vue';
import SettingsPage from '../pages/settings.vue';
import SignoutPage from '../pages/singout.vue';

import DynamicRoutePage from '../pages/dynamic-route.vue';
import RequestAndLoad from '../pages/request-and-load.vue';
import NotFoundPage from '../pages/404.vue';

import cookies from 'vue-cookies';
import { store } from './store.js';

//try console log store
// handle page reload
var user = null
if (cookies.isKey("user")) {
    user = cookies.get('user')
    store.commit('setUserProfile', user)
    store.dispatch('fetchUserProfile')
}

function checkAuth(to, from, resolve, reject) {
  var router = this;
  var userProfile = null;
  if (cookies.isKey("user")) {
    userProfile = cookies.get('user')
  }
  if (userProfile == null) {
		reject()
		router.navigate('/login/')
	} else {
		resolve();
	}
}

var routes = [
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/',
    component: HomePage,
    beforeEnter: checkAuth,
  },
  {
    path: '/terminos/',
    component: TerminosPage,
  },
  {
    path: '/form/',
    component: FormPage,
  },
  {
    path: '/catalog/',
    component: CatalogPage,
  },
  {
    path: '/singout/',
    component: SignoutPage,
  },
  {
    path: '/patient/:id/',
    component: PatientPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: HomePage,
  },
];

export default routes;
