<template>
    <f7-page login-screen name="login" class="page no-navbar no-toolbar no-swipeback">
        <transition name="fade">
        <div v-if="performingRequest" class="loading">
            <f7-preloader color="green"></f7-preloader>
        </div>
        </transition>
        <f7-block>
            <div class="logo-box">
             <div><img data-src="./static/images/logoAlpha.png" class="lazy logo" alt="ReumApp" title="ReumApp"></div>
            </div>
            <f7-block v-if="showLoginForm" >
                <f7-list form autocomplete="off" class="elevation-3 bg-box">
                    <input autocomplete="false" name="hidden" type="text" style="display:none;" />
                    <f7-login-screen-title>¡Bienvenido!</f7-login-screen-title>
                    <p class="text-align-center">Para continuar por favor entre con su usuario de ReumApp.</p>
                    <p v-if="errors.length" class="text-align-center warning">
                        <span v-if="errors.length > 1">Por favor corriga los siguientes errores:</span>
                        <span v-else>Por favor corriga el siguiente error:</span>
                        <ul class="mt-2">
                        <li v-for="error in errors" :key="error">
                            {{ error }}
                        </li>
                        </ul>
                    </p> 
                    <f7-list-input
                        type="text"
                        name="email"
                        autocomplete="off"
                        placeholder="Su correo"
                        class="alf-input"
                        :value="email"
                        @input="email = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        :type="type"
                        name="password"
                        autocomplete="off"
                        placeholder="Su contraseña"
                        :value="password"
                        @input="password = $event.target.value">
                        <div @click="showPassword" slot="content-end">
                        <f7-icon :f7="passwordIcon"></f7-icon>
                        </div>
                    </f7-list-input>
                    <f7-list>
                        <f7-row tag="p">
                            <f7-button class="col" round fill @click="userLogin">Entrar</f7-button>
                        </f7-row>
                        <f7-row tag="p">
                            <f7-button class="col" round small @click="togglePasswordReset">Olvido su clave</f7-button>
                        </f7-row>
                    </f7-list>
                </f7-list>    
                <f7-block-footer>
                   <f7-button class="col" small @click="toggleForm">Soy nuevo usuario</f7-button>
                </f7-block-footer>
			</f7-block>
			<f7-block v-if="!showLoginForm && !showForgotPassword" >
                <f7-list form autocomplete="off" class="elevation-3 bg-box">
                    <f7-login-screen-title>Crear una cuenta</f7-login-screen-title>
                    <p class="text-align-center">Nos complace tenerlo aquí. <br> <small>Por favor llene los siguientes datos para continuar.</small></p>
                    <p v-if="errors.length" class="text-align-center warning">
                        <span v-if="errors.length > 1">Por favor corriga los siguientes errores:</span>
                        <span v-else>Por favor corriga el siguiente error:</span>
                        <ul class="mt-2">
                        <li v-for="error in errors" :key="error">
                            {{ error }}
                        </li>
                        </ul>
                    </p> 
                    <f7-list-input
                        type="text"
                        name="firstname"
                        placeholder="Su nombre"
                        :value="signupForm.firstname"
                        @input="signupForm.firstname = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        type="text"
                        name="lastname"
                        placeholder="Su apellido"
                        :value="signupForm.lastname"
                        @input="signupForm.lastname = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        type="email"
                        name="email"
                        placeholder="Su email"
                        :value="signupForm.email"
                        @input="signupForm.email = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        label="Genero"
                        type="select"
                        defaultValue="Masculino"
                        placeholder="Seleccione"
                        :value="signupForm.genero"
                        @input="signupForm.genero = $event.target.value"
                    >
                        <option value="Masculino">Masculino</option>
                        <option value="Femenino">Femenino</option>
                    </f7-list-input>
                     <f7-list-input
                        label="Especialidad"
                        type="select"
                        defaultValue="Reumatología"
                        :value="signupForm.especialidad"
                        @input="signupForm.especialidad = $event.target.value"
                    >
                        <option value="Anestesiología">Anestesiología</option>
                        <option value="Cardiología">Cardiología</option>
                        <option value="Cirugía">Cirugía</option>
                        <option value="Coloproctología">Coloproctología</option>
                        <option value="Cuidados intensivos">Cuidados intensivos</option>
                        <option value="Dermatología">Dermatología</option>
                        <option value="Dolor y cuidados paliativos">Dolor y cuidados paliativos</option>
                        <option value="Endocrinología">Endocrinología</option>
                        <option value="Epidemiología">Epidemiología</option>
                        <option value="Farmacología">Farmacología</option>
                        <option value="Fisiatria">Fisiatria</option>
                        <option value="Gastroenterología">Gastroenterología</option>
                        <option value="Genética">Genética</option>
                        <option value="Geriatría">Geriatría</option>
                        <option value="Ginecología">Ginecología</option>
                        <option value="Hematología">Hematología</option>
                        <option value="Infectologia">Infectologia</option>
                        <option value="Inmunología">Inmunología</option>
                        <option value="Medicina del trabajo">Medicina del trabajo</option>
                        <option value="Medicina de urgencias">Medicina de urgencias</option>
                        <option value="Medicina deportiva">Medicina deportiva</option>
                        <option value="Medicina familiar">Medicina familiar</option>
                        <option value="Medicina general">Medicina general</option>
                        <option value="Medicina interna">Medicina interna</option>
                        <option value="Nefrología">Nefrología</option>
                        <option value="Neumología">Neumología</option>
                        <option value="Neurocirugía">Neurocirugía</option>
                        <option value="Neurología">Neurología</option>
                        <option value="Oftalmología">Oftalmología</option>
                        <option value="Oncología">Oncología</option>
                        <option value="Ortopedia">Ortopedia</option>
                        <option value="Pediatría">Pediatría</option>
                        <option value="Psiquiatría">Psiquiatría</option>
                        <option value="Radiología">Radiología</option>
                        <option value="Reumatología">Reumatología</option>
                        <option value="Salud pública">Salud pública</option>
                        <option value="Otro">Otro</option>
                    </f7-list-input>
                    <f7-list-input
                        label="Genero"
                        type="select"
                        defaultValue="Colombia"
                        placeholder="Seleccione"
                        :value="signupForm.pais"
                        @input="signupForm.pais = $event.target.value"
                    >
                        <option value="Afganistán" id="AF">Afganistán</option>
                        <option value="Albania" id="AL">Albania</option>
                        <option value="Alemania" id="DE">Alemania</option>
                        <option value="Andorra" id="AD">Andorra</option>
                        <option value="Angola" id="AO">Angola</option>
                        <option value="Anguila" id="AI">Anguila</option>
                        <option value="Antártida" id="AQ">Antártida</option>
                        <option value="Antigua y Barbuda" id="AG">Antigua y Barbuda</option>
                        <option value="Antillas holandesas" id="AN">Antillas holandesas</option>
                        <option value="Arabia Saudí" id="SA">Arabia Saudí</option>
                        <option value="Argelia" id="DZ">Argelia</option>
                        <option value="Argentina" id="AR">Argentina</option>
                        <option value="Armenia" id="AM">Armenia</option>
                        <option value="Aruba" id="AW">Aruba</option>
                        <option value="Australia" id="AU">Australia</option>
                        <option value="Austria" id="AT">Austria</option>
                        <option value="Azerbaiyán" id="AZ">Azerbaiyán</option>
                        <option value="Bahamas" id="BS">Bahamas</option>
                        <option value="Bahrein" id="BH">Bahrein</option>
                        <option value="Bangladesh" id="BD">Bangladesh</option>
                        <option value="Barbados" id="BB">Barbados</option>
                        <option value="Bélgica" id="BE">Bélgica</option>
                        <option value="Belice" id="BZ">Belice</option>
                        <option value="Benín" id="BJ">Benín</option>
                        <option value="Bermudas" id="BM">Bermudas</option>
                        <option value="Bhután" id="BT">Bhután</option>
                        <option value="Bielorrusia" id="BY">Bielorrusia</option>
                        <option value="Birmania" id="MM">Birmania</option>
                        <option value="Bolivia" id="BO">Bolivia</option>
                        <option value="Bosnia y Herzegovina" id="BA">Bosnia y Herzegovina</option>
                        <option value="Botsuana" id="BW">Botsuana</option>
                        <option value="Brasil" id="BR">Brasil</option>
                        <option value="Brunei" id="BN">Brunei</option>
                        <option value="Bulgaria" id="BG">Bulgaria</option>
                        <option value="Burkina Faso" id="BF">Burkina Faso</option>
                        <option value="Burundi" id="BI">Burundi</option>
                        <option value="Cabo Verde" id="CV">Cabo Verde</option>
                        <option value="Camboya" id="KH">Camboya</option>
                        <option value="Camerún" id="CM">Camerún</option>
                        <option value="Canadá" id="CA">Canadá</option>
                        <option value="Chad" id="TD">Chad</option>
                        <option value="Chile" id="CL">Chile</option>
                        <option value="China" id="CN">China</option>
                        <option value="Chipre" id="CY">Chipre</option>
                        <option value="Ciudad estado del Vaticano" id="VA">Ciudad estado del Vaticano
                        </option>
                        <option value="Colombia" id="CO">Colombia</option>
                        <option value="Comores" id="KM">Comores</option>
                        <option value="Congo" id="CG">Congo</option>
                        <option value="Corea" id="KR">Corea</option>
                        <option value="Corea del Norte" id="KP">Corea del Norte</option>
                        <option value="Costa del Marfíl" id="CI">Costa del Marfíl</option>
                        <option value="Costa Rica" id="CR">Costa Rica</option>
                        <option value="Croacia" id="HR">Croacia</option>
                        <option value="Cuba" id="CU">Cuba</option>
                        <option value="Dinamarca" id="DK">Dinamarca</option>
                        <option value="Djibouri" id="DJ">Djibouri</option>
                        <option value="Dominica" id="DM">Dominica</option>
                        <option value="Ecuador" id="EC">Ecuador</option>
                        <option value="Egipto" id="EG">Egipto</option>
                        <option value="El Salvador" id="SV">El Salvador</option>
                        <option value="Emiratos Arabes Unidos" id="AE">Emiratos Arabes Unidos</option>
                        <option value="Eritrea" id="ER">Eritrea</option>
                        <option value="Eslovaquia" id="SK">Eslovaquia</option>
                        <option value="Eslovenia" id="SI">Eslovenia</option>
                        <option value="España" id="ES">España</option>
                        <option value="Estados Unidos" id="US">Estados Unidos</option>
                        <option value="Estonia" id="EE">Estonia</option>
                        <option value="c" id="ET">Etiopía</option>
                        <option value="Ex-República Yugoslava de Macedonia" id="MK">Ex-República
                            Yugoslava de Macedonia</option>
                        <option value="Filipinas" id="PH">Filipinas</option>
                        <option value="Finlandia" id="FI">Finlandia</option>
                        <option value="Francia" id="FR">Francia</option>
                        <option value="Gabón" id="GA">Gabón</option>
                        <option value="Gambia" id="GM">Gambia</option>
                        <option value="Georgia" id="GE">Georgia</option>
                        <option value="Georgia del Sur y las islas Sandwich del Sur" id="GS">Georgia del
                            Sur y las islas Sandwich del Sur</option>
                        <option value="Ghana" id="GH">Ghana</option>
                        <option value="Gibraltar" id="GI">Gibraltar</option>
                        <option value="Granada" id="GD">Granada</option>
                        <option value="Grecia" id="GR">Grecia</option>
                        <option value="Groenlandia" id="GL">Groenlandia</option>
                        <option value="Guadalupe" id="GP">Guadalupe</option>
                        <option value="Guam" id="GU">Guam</option>
                        <option value="Guatemala" id="GT">Guatemala</option>
                        <option value="Guayana" id="GY">Guayana</option>
                        <option value="Guayana francesa" id="GF">Guayana francesa</option>
                        <option value="Guinea" id="GN">Guinea</option>
                        <option value="Guinea Ecuatorial" id="GQ">Guinea Ecuatorial</option>
                        <option value="Guinea-Bissau" id="GW">Guinea-Bissau</option>
                        <option value="Haití" id="HT">Haití</option>
                        <option value="Holanda" id="NL">Holanda</option>
                        <option value="Honduras" id="HN">Honduras</option>
                        <option value="Hong Kong R. A. E" id="HK">Hong Kong R. A. E</option>
                        <option value="Hungría" id="HU">Hungría</option>
                        <option value="India" id="IN">India</option>
                        <option value="Indonesia" id="ID">Indonesia</option>
                        <option value="Irak" id="IQ">Irak</option>
                        <option value="Irán" id="IR">Irán</option>
                        <option value="Irlanda" id="IE">Irlanda</option>
                        <option value="Isla Bouvet" id="BV">Isla Bouvet</option>
                        <option value="Isla Christmas" id="CX">Isla Christmas</option>
                        <option value="Isla Heard e Islas McDonald" id="HM">Isla Heard e Islas McDonald
                        </option>
                        <option value="Islandia" id="IS">Islandia</option>
                        <option value="Islas Caimán" id="KY">Islas Caimán</option>
                        <option value="Islas Cook" id="CK">Islas Cook</option>
                        <option value="Islas de Cocos o Keeling" id="CC">Islas de Cocos o Keeling
                        </option>
                        <option value="Islas Faroe" id="FO">Islas Faroe</option>
                        <option value="Islas Fiyi" id="FJ">Islas Fiyi</option>
                        <option value="Islas Malvinas Islas Falkland" id="FK">Islas Malvinas Islas
                            Falkland</option>
                        <option value="Islas Marianas del norte" id="MP">Islas Marianas del norte
                        </option>
                        <option value="Islas Marshall" id="MH">Islas Marshall</option>
                        <option value="Islas menores de Estados Unidos" id="UM">Islas menores de Estados
                            Unidos</option>
                        <option value="Islas Palau" id="PW">Islas Palau</option>
                        <option value="Islas Salomón" d="SB">Islas Salomón</option>
                        <option value="Islas Tokelau" id="TK">Islas Tokelau</option>
                        <option value="Islas Turks y Caicos" id="TC">Islas Turks y Caicos</option>
                        <option value="Islas Vírgenes EE.UU." id="VI">Islas Vírgenes EE.UU.</option>
                        <option value="Islas Vírgenes Reino Unido" id="VG">Islas Vírgenes Reino Unido
                        </option>
                        <option value="Israel" id="IL">Israel</option>
                        <option value="Italia" id="IT">Italia</option>
                        <option value="Jamaica" id="JM">Jamaica</option>
                        <option value="Japón" id="JP">Japón</option>
                        <option value="Jordania" id="JO">Jordania</option>
                        <option value="Kazajistán" id="KZ">Kazajistán</option>
                        <option value="Kenia" id="KE">Kenia</option>
                        <option value="Kirguizistán" id="KG">Kirguizistán</option>
                        <option value="Kiribati" id="KI">Kiribati</option>
                        <option value="Kuwait" id="KW">Kuwait</option>
                        <option value="Laos" id="LA">Laos</option>
                        <option value="Lesoto" id="LS">Lesoto</option>
                        <option value="Letonia" id="LV">Letonia</option>
                        <option value="Líbano" id="LB">Líbano</option>
                        <option value="Liberia" id="LR">Liberia</option>
                        <option value="Libia" id="LY">Libia</option>
                        <option value="Liechtenstein" id="LI">Liechtenstein</option>
                        <option value="Lituania" id="LT">Lituania</option>
                        <option value="Luxemburgo" id="LU">Luxemburgo</option>
                        <option value="Macao R. A. E" id="MO">Macao R. A. E</option>
                        <option value="Madagascar" id="MG">Madagascar</option>
                        <option value="Malasia" id="MY">Malasia</option>
                        <option value="Malawi" id="MW">Malawi</option>
                        <option value="Maldivas" id="MV">Maldivas</option>
                        <option value="Malí" id="ML">Malí</option>
                        <option value="Malta" id="MT">Malta</option>
                        <option value="Marruecos" id="MA">Marruecos</option>
                        <option value="Martinica" id="MQ">Martinica</option>
                        <option value="Mauricio" id="MU">Mauricio</option>
                        <option value="Mauritania" id="MR">Mauritania</option>
                        <option value="Mayotte" id="YT">Mayotte</option>
                        <option value="México" id="MX">México</option>
                        <option value="Micronesia" id="FM">Micronesia</option>
                        <option value="Moldavia" id="MD">Moldavia</option>
                        <option value="Mónaco" id="MC">Mónaco</option>
                        <option value="Mongolia" id="MN">Mongolia</option>
                        <option value="Montserrat" id="MS">Montserrat</option>
                        <option value="Mozambique" id="MZ">Mozambique</option>
                        <option value="Namibia" id="NA">Namibia</option>
                        <option value="Nauru" id="NR">Nauru</option>
                        <option value="Nepal" id="NP">Nepal</option>
                        <option value="Nicaragua" id="NI">Nicaragua</option>
                        <option value="Níger" id="NE">Níger</option>
                        <option value="Nigeria" id="NG">Nigeria</option>
                        <option value="Niue" id="NU">Niue</option>
                        <option value="Norfolk" id="NF">Norfolk</option>
                        <option value="Noruega" id="NO">Noruega</option>
                        <option value="Nueva Caledonia" id="NC">Nueva Caledonia</option>
                        <option value="Nueva Zelanda" id="NZ">Nueva Zelanda</option>
                        <option value="Omán" id="OM">Omán</option>
                        <option value="Panamá" id="PA">Panamá</option>
                        <option value="Papua Nueva Guinea" id="PG">Papua Nueva Guinea</option>
                        <option value="Paquistán" id="PK">Paquistán</option>
                        <option value="Paraguay" id="PY">Paraguay</option>
                        <option value="Perú" id="PE">Perú</option>
                        <option value="Pitcairn" id="PN">Pitcairn</option>
                        <option value="Polinesia francesa" id="PF">Polinesia francesa</option>
                        <option value="Polonia" id="PL">Polonia</option>
                        <option value="Portugal" id="PT">Portugal</option>
                        <option value="Puerto Rico" id="PR">Puerto Rico</option>
                        <option value="Qatar" id="QA">Qatar</option>
                        <option value="Reino Unido" id="UK">Reino Unido</option>
                        <option value="República Centroafricana" id="CF">República Centroafricana
                        </option>
                        <option value="República Checa" id="CZ">República Checa</option>
                        <option value="República de Sudáfrica" id="ZA">República de Sudáfrica</option>
                        <option value="República Democrática del Congo Zaire" id="CD">República
                            Democrática del Congo Zaire</option>
                        <option value="República Dominicana" id="DO">República Dominicana</option>
                        <option value="Reunión" id="RE">Reunión</option>
                        <option value="Ruanda" id="RW">Ruanda</option>
                        <option value="Rumania" id="RO">Rumania</option>
                        <option value="Rusia" id="RU">Rusia</option>
                        <option value="Samoa" id="WS">Samoa</option>
                        <option value="Samoa occidental" id="AS">Samoa occidental</option>
                        <option value="San Kitts y Nevis" id="KN">San Kitts y Nevis</option>
                        <option value="San Marino" id="SM">San Marino</option>
                        <option value="San Pierre y Miquelon" id="PM">San Pierre y Miquelon</option>
                        <option value="San Vicente e Islas Granadinas" id="VC">San Vicente e Islas
                            Granadinas</option>
                        <option value="Santa Helena" id="SH">Santa Helena</option>
                        <option value="Santa Lucía" id="LC">Santa Lucía</option>
                        <option value="Santo Tomé y Príncipe" id="ST">Santo Tomé y Príncipe</option>
                        <option value="Senegal" id="SN">Senegal</option>
                        <option value="Serbia y Montenegro" id="YU">Serbia y Montenegro</option>
                        <option value="Sychelles" id="SC">Seychelles</option>
                        <option value="Sierra Leona" id="SL">Sierra Leona</option>
                        <option value="Singapur" id="SG">Singapur</option>
                        <option value="Siria" id="SY">Siria</option>
                        <option value="Somalia" id="SO">Somalia</option>
                        <option value="Sri Lanka" id="LK">Sri Lanka</option>
                        <option value="Suazilandia" id="SZ">Suazilandia</option>
                        <option value="Sudán" id="SD">Sudán</option>
                        <option value="Suecia" id="SE">Suecia</option>
                        <option value="Suiza" id="CH">Suiza</option>
                        <option value="Surinam" id="SR">Surinam</option>
                        <option value="Svalbard" id="SJ">Svalbard</option>
                        <option value="Tailandia" id="TH">Tailandia</option>
                        <option value="Taiwán" id="TW">Taiwán</option>
                        <option value="Tanzania" id="TZ">Tanzania</option>
                        <option value="Tayikistán" id="TJ">Tayikistán</option>
                        <option value="Territorios británicos del océano Indico" id="IO">Territorios
                            británicos del océano Indico</option>
                        <option value="Territorios franceses del sur" id="TF">Territorios franceses del
                            sur</option>
                        <option value="Timor Oriental" id="TP">Timor Oriental</option>
                        <option value="Togo" id="TG">Togo</option>
                        <option value="Tonga" id="TO">Tonga</option>
                        <option value="Trinidad y Tobago" id="TT">Trinidad y Tobago</option>
                        <option value="Túnez" id="TN">Túnez</option>
                        <option value="Turkmenistán" id="TM">Turkmenistán</option>
                        <option value="Turquía" id="TR">Turquía</option>
                        <option value="Tuvalu" id="TV">Tuvalu</option>
                        <option value="Ucrania" id="UA">Ucrania</option>
                        <option value="Uganda" id="UG">Uganda</option>
                        <option value="Uruguay" id="UY">Uruguay</option>
                        <option value="Uzbekistán" id="UZ">Uzbekistán</option>
                        <option value="Vanuatu" id="VU">Vanuatu</option>
                        <option value="Venezuela" id="VE">Venezuela</option>
                        <option value="Vietnam" id="VN">Vietnam</option>
                        <option value="Wallis y Futuna" id="WF">Wallis y Futuna</option>
                        <option value="Yemen" id="YE">Yemen</option>
                        <option value="Zambia" id="ZM">Zambia</option>
                        <option value="Zimbabue" id="ZW">Zimbabue</option>
                    </f7-list-input>
                    <f7-list-input
                        type="password"
                        name="password"
                        placeholder="Su password"
                        :value="signupForm.password"
                        @input="signupForm.password = $event.target.value"
                    ></f7-list-input>
                    <p class="mt-3 text-align-center">
                        <span class="text-mute">Al registrarse esta aceptando nuestros</span>
                        <a class="link" href="/terminos/">terminos y condiciones</a>.
                    </p>
                    <f7-list>
                        <f7-row tag="p">
                            <f7-button class="col" round fill @click="userRegister">Registrarse</f7-button>
                        </f7-row>
                    </f7-list>
                </f7-list>
                <f7-block-footer>
                    <f7-button class="col" small @click="toggleForm">Volver al login</f7-button>
                </f7-block-footer>     
			</f7-block>
			<f7-block v-if="showForgotPassword">
                <template v-if="!passwordResetSuccess">
                    <f7-list form autocomplete="off" class="elevation-3 bg-box">
                        <f7-login-screen-title>Cambiar contraseña</f7-login-screen-title>
                        <p class="text-align-center">Le enviaremos un email para cambiar su contraseña</p>
                        <p v-if="errors.length" class="text-align-center warning">
                            <ul class="mt-2">
                            <li v-for="error in errors" :key="error">
                                {{ error }}
                            </li>
                            </ul>
                        </p> 
                        <f7-list-input
                            type="text"
                            name="email"
                            placeholder="Su email"
                            :value="passwordForm.email"
                            @input="passwordForm.email = $event.target.value"
                        ></f7-list-input>
                        <f7-row tag="p">
                            <f7-button class="col" round fill @click="recoverPassword">Enviar</f7-button>
                        </f7-row>
                    </f7-list>
                </template>
                <template v-else>
                    <f7-block class="elevation-3 bg-box">
                        <f7-login-screen-title>Email enviado</f7-login-screen-title>
                        <p class="text-align-center">Revise su correo tendra un enlace para resetear su contraseña</p>
                    </f7-block>
                </template>
                <f7-block-footer>
                    <f7-button class="col" small @click="togglePasswordReset">Volver al login</f7-button>
                </f7-block-footer>
			</f7-block>
        </f7-block>
    </f7-page>
</template>

<script>
    import axios from "axios";
	import VueAxios from "vue-axios";
    import { mapState } from "vuex";
    
    export default {
        computed: {
			...mapState([
				"userProfile",
                "performingRequest",
			]),
		},
		data() {
			return {
				// Login screen data
				email: "",
                password: "",
                type: "password",
                passwordIcon: "eye",
				signupForm: {
                    firstname: "",
                    lastname: "",
                    email: "",
                    especialidad: "Reumatología",
                    pais: "Colombia",
                    password: "",
                    genero: "Masculino",
                },
				passwordForm: {
					email: ""
				},
				showLoginForm: true,
				showForgotPassword: false,
				passwordResetSuccess: false,
				errorMsg: "",
                errors: []
			};
        },
        methods: {
            toggleForm() {
                this.errors = []
				this.errorMsg = "";
				this.showLoginForm = !this.showLoginForm;
			},
			togglePasswordReset() {
                this.errors = []
				if (this.showForgotPassword) {
					this.showLoginForm = true;
					this.showForgotPassword = false;
					this.passwordResetSuccess = false;
				} else {
					this.showLoginForm = false;
					this.showForgotPassword = true;
				}
            },
            async userLogin (e) {
                // Validar Formulario
                this.errors = []
                var that = this;
                if (!this.email) {
                    this.errors.push('El correo es obligatorio.')
                } else if (!this.validEmail(this.email)) {
                    this.errors.push('El email es incorrecto.')
                }
                if (!this.password) {
                    this.errors.push('La clave es obligatoria.')
                }

                if (!this.errors.length) {
                    const url = 'https://reumapp.alfamediaweb.com/auth.php?callback=?'
                    const data = new FormData()
                    data.append('email', this.email)
                    data.append('password', this.password)
                    data.append('loginv2', '')
                    
                   this.$store.dispatch("performRequest");
                    try {
                        const res = await axios.post(url, data)
                        if (res.data.type === 'success') {
                            this.$cookies.set('user', res.data.user);
                            this.$store.dispatch("fetchUserProfile", res.data.user);
                            this.$store.dispatch("performRequest");
                            that.$f7.views.main.router.navigate("/home/");
                            return true
                        } else {
                            this.$store.dispatch("performRequest");
                            this.errors.push(res.data.message)
                        }
                    } catch (err) {
                        this.errors = []
                        this.errors.push('Error de conexión.')
                        this.$store.dispatch("performRequest");
                    }
                }
                e.preventDefault()
            },
            async userRegister (e) {
                // Validar Formulario
                this.errors = []
                var that = this;
                if (!this.signupForm.firstname) {
                    this.errors.push('El nombre es obligatorio.')
                }
                 if (!this.signupForm.lastname) {
                    this.errors.push('El apellido es obligatorio.')
                }
                if (!this.signupForm.email) {
                    this.errors.push('El correo es obligatorio.')
                } else if (!this.validEmail(this.signupForm.email)) {
                    this.errors.push('El email es incorrecto.')
                }
                if (!this.signupForm.password) {
                    this.errors.push('La clave es obligatoria.')
                } else if (!this.validPassword(this.signupForm.password)) {
                    this.errors.push('La clave dene tener nimino 6 caracteres.')
                }
                if (!this.errors.length) {
                    const url = 'https://reumapp.alfamediaweb.com/auth.php?callback=?'
                    const data = new FormData()
                    data.append('firstname', this.signupForm.firstname)
                    data.append('lastname', this.signupForm.lastname)
                    data.append('email', this.signupForm.email)
                    data.append('especialidad', this.signupForm.especialidad)
                    data.append('sexo', this.signupForm.genero)
                    data.append('pais', this.signupForm.pais)
                    data.append('signup', '')

                    this.$store.dispatch("performRequest");

                    try {
                        const res = await axios.post(url, data)
                        if (res.data === 'success') {
                            var user = {
                                id: 'tmp00',
                                email: this.signupForm.email
                            }
                            this.$cookies.set('user', res.data.user);
                            this.$store.dispatch("fetchUserProfile", res.data.user);
                            this.$store.dispatch("performRequest");
                            that.$f7.views.main.router.navigate("/home/");
                            return true
                        } else if (res.data === "exist") {
                            this.errors.push('Ya existe un usuario con ese correo')
                            this.$store.dispatch("performRequest");
                        } else if (res.data === "failed"){
                            this.errors.push('Algo salió mal en el registro, asegurese de tener datos y trate de nuevo!')
                            this.$store.dispatch("performRequest");
                        }
                    } catch (err) {
                        this.errors = []
                        this.errors.push('Error de conexión.')
                        console.log('Error de conexion');
                        this.$store.dispatch("performRequest");
                        console.log(err)
                    }
                }
                e.preventDefault()
            },
            async recoverPassword (e) {
                // Validar Formulario
                this.errors = []
                var that = this;
                if (!this.passwordForm.email) {
                    this.errors.push('El correo es obligatorio.')
                } else if (!this.validEmail(this.passwordForm.email)) {
                    this.errors.push('El email es incorrecto.')
                }
               
                if (!this.errors.length) {
                    const url = 'https://reumapp.alfamediaweb.com/auth.php?callback=?'
                    const data = new FormData()
                    data.append('email', this.passwordForm.email)
                    data.append('forget_password', true)
                    
                   this.$store.dispatch("performRequest");
                    try {
                        const res = await axios.post(url, data)
                        if (res.data === 'success') {
                            this.errors.push('Hemos enviado su contraseña al correo ' + this.passwordForm.email + ', por favor verifique.')
                        } else if (res.data === 'invalid') {
                            this.errors.push('Este correo no esta registrado aun.')
                        }
                        this.$store.dispatch("performRequest");
                        console.log(res.data)
                        return true
                    } catch (err) {
                        this.errors.push('Error de conexión.')
                        this.$store.dispatch("performRequest");
                    }
                }
                e.preventDefault()
            },
            showPassword() {
                this.errors = []
                if(this.type === 'password') {
                    this.type = 'text'
                    this.passwordIcon = 'eye_slash_fill'
                } else {
                    this.type = 'password'
                    this.passwordIcon = 'eye_fill'
                }
            },
            validEmail: function (email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            validPassword: function (pass) {
                const re = /[0-9a-zA-Z]{6,}/ 
                return re.test(pass)
            }
        }
    }
</script>   

<style scoped>
.bg-box {
  background-color: rgba(53,151,70, 0.05) ;
  padding: 15px 30px;
}
.warning {
  background-color: rgba(255,255,215, 0.25) ;
  padding: 15px 30px; 
}

.logo {
    width: 200px;
}

.logo-box {
    display:flex;
    justify-content:center;
    align-items:center;
}
</style>




	
