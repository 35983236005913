<template>
  <f7-page name="patient">
    <f7-navbar title="ReumApp - Ficha de transición AIJ" back-link="Back"></f7-navbar>
    <div class="container">
      <f7-block>
        <f7-block strong inset>
          <f7-block-title large>Paciente: {{patient.nombre}} </f7-block-title>
        </f7-block>
        <div class="row">
          <div class="col-33">  
            <f7-block strong inset no-hairlines>
              <f7-block-title>Datos generales</f7-block-title>  
              <strong>Fecha de nacimiento:</strong> {{patient.fecha_de_nacimiento}}<br>
              <strong>Genero:</strong> {{patient.genero}}<br>
              <strong>Teléfono:</strong> {{patient.telefono}}<br>
              <strong>Remitido en:</strong> {{patient.created_on}}
            </f7-block>
          </div>
          <div class="col-33">  
            <f7-block strong inset no-hairlines>
              <f7-block-title>Historia de la enfermedad</f7-block-title>  
              <strong>Tipo de AIJ:</strong> {{patient.tipo_aij}}<br>
              <strong>Fecha de diagnostico AIJ:</strong> {{patient.fecha_diagnostico_aij}}<br>
              <strong>En remisión:</strong> {{patient.fecha_remision_aij}}<br>
              <strong>Reportada como enfermedad huerfana:</strong> {{patient.fecha_reporte_huerfana}}
            </f7-block>
          </div>
          <div class="col-33">  
            <f7-block strong inset no-hairlines>
              <f7-block-title>Complicaciones</f7-block-title>  
              <strong>Tipo de complicación:</strong> {{patient.complicaciones}}<br>
              <strong>Otra:</strong> {{patient.otras_complicaciones}}<br>
            </f7-block>
          </div>
        </div> 
        <div class="row">
          <div class="col-30">
            <f7-block strong inset>    
              <f7-block-title medium>Laboratorios</f7-block-title>
              <f7-list>
                <f7-list-item title="ANA" :badge="checkLabValue(patient.ana)" :badge-color="labColor"></f7-list-item>
                <f7-list-item title="Factor Reumatoideo" :badge="checkLabValue(patient.factor_reumatoideo)" :badge-color="labColor"></f7-list-item>
                <f7-list-item title="HLA B27" :badge="checkLabValue(patient.hla_b27)" :badge-color="labColor"></f7-list-item>
                <f7-list-item title="PPD" :badge="checkLabValue(patient.ppd)" :badge-color="labColor"></f7-list-item>
                <li v-if="patient.ppd == 'on'">
                  <ul>
                    <f7-list-item title="Valor PPD" :badge="patient.ppd_value" badge-color="blue"></f7-list-item>
                    <f7-list-item title="Fecha PPD" :badge="patient.fecha_ppd" badge-color="blue"></f7-list-item>
                  </ul>
                </li>
                <f7-list-item title="Quantiferon" :badge="checkLabValue(patient.quantiferon)" :badge-color="labColor"></f7-list-item>  
              </f7-list>
            </f7-block>  
          </div>
          <div class="col-70">
            <f7-block strong>    
              <f7-block-title medium>Medicinas</f7-block-title>
                <div class="data-table">
                  <table>
                    <thead>
                      <tr>
                        <th class="label-cell" width="15%">
                          <h3>Nombre</h3>
                        </th>
                        <th class="label-cell">
                          <h3>Dosis</h3>
                        </th>
                        <th class="label-cell" width="20%">
                          <h3>Fecha inicio</h3>
                        </th>
                        <th class="label-cell" width="15%">
                          <h3>Fecha descontinuación</h3>
                        </th>
                        <th class="label-cell" width="20%">
                        <h3>Razón de descontinuación</h3>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-html="buildHtmlTable(patient.medicamentos)" >
                    </tbody>  
                  </table>
                </div>
            </f7-block>
          </div>
      </div>
      </f7-block>   
    </div>  
  </f7-page>
</template>
<script>
  import axios from "axios";
  import VueAxios from "vue-axios";

  export default {
    data: function () {
      return {
        patient: '',
        labColor: ''
      };
    },
    mounted(){
      this.getPatientInfo()
    },
    methods: {
       async getPatientInfo() {
          var id = this.$f7route.params.id;
          var url = "https://reumappdata.alfamediaweb.com/reumappdata/items/transicion?filter[id][eq]=" + id;
          var dataString = '';
        try {
          const res = await axios.get(url, dataString)
          this.patient = eval(res.data.data[0])
        } catch (err) {
          console.log(err)
        }
       },
        checkLabValue(value){
          if (value === 'on'){
            this.labColor = 'red'
            return 'Positivo'
          } else {
            this.labColor = 'green'
            return 'Negativo'
          }
        },
        buildHtmlTable(data) {
          var nCol = 'vacio'
          var rHtml = ''
          var count = 0

          for (var key in data) {
              if (data.hasOwnProperty(key)) {
                count ++
                if (key.includes('med-')){
                  var res = key.split("_");
                  var name = res[0].replace('med-', '');
                  if (nCol != name) {
                    nCol = name
                    rHtml = rHtml + "<td>" + nCol + "</td>"
                  }
                  var value = data[key];
                  rHtml = rHtml + "<td>" + value + "</td>"
                } 
                if (count % 4 == 0 ) {
                  rHtml = rHtml + "</tr><tr>"
                }
              }
          }
          return rHtml
        }
    }
  };
</script>
