import Vue from 'vue'
import Vuex from 'vuex'
import cookies from 'vue-cookies'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        userProfile: null,
        performingRequest: false,
    },
    actions: {
        clearData({ commit }) {
            commit('setCurrentUser', null)
            commit('setUserProfile', {})
        },
        fetchUserProfile({ commit, state }) {
            var user = null;
            if (cookies.isKey("user")) {
                user = cookies.get('user')
                store.commit('setUserProfile', user)
            }
        },
        performRequest({commit }){
            var value = store.state.performingRequest;
            commit('setPerformingRequest', !value);
        },
    },
    mutations: {
        setUserProfile(state, val) {
            state.userProfile = val
        },
        setPerformingRequest(state, val) {
            state.performingRequest = val
        },
    }
})