<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar large :sliding="false">
      <f7-nav-right>
        <f7-link
          icon-ios="f7:multiply_circle_fill"
          icon-aurora="f7:multiply_circle_fill"
          icon-md="material:cancel"
          @click="logout"
        ></f7-link>
      </f7-nav-right>
      <f7-nav-title sliding>Reumapp Utilidades</f7-nav-title>
      <f7-nav-title-large>Reumapp - Utilidades</f7-nav-title-large>
    </f7-navbar>

    <!-- Page content-->
    <div class="container">
      <f7-block>
        <f7-block-title large>Bienvenido {{ userProfile.name }}</f7-block-title>
        <f7-block strong>
          <p>
            En este sitio encontrará diferentes utilidades de ReumApp. 
          </p>
        </f7-block>


        <f7-card class="demo-card-header-pic">
          <f7-card-content>
            <p class="date">Publicado el 29 de Septiembre, 2022</p>
            <h1>Eliminar mi usuario</h1>
            <p>
              Para solicitar ser dado de baja de ReumApp, haga click en el siguiente botón.
            </p>
            <f7-button fill href="/singout/">Eliminar mi usuario</f7-button>
          </f7-card-content>
        </f7-card>

        <f7-card class="demo-card-header-pic">
          <f7-card-header
            class="no-border alf-card-title"
            valign="bottom"
            style="
              background-image: url(https://reumapp.alfamediaweb.com/static/images/me-reumapp/diter/diter.jpg);
            "
            ><div class="alf-overlay">Curso DITER - Diagnóstico Temprano de Enfermedades
            Reumáticas</div></f7-card-header
          >
          <f7-card-content>
            <p class="date">Publicado el 29 de Septiembre, 2021</p>
            <p>
              Los usuarios del curso DITER tienen a su disposicion el curso en
              la aplicación. La pueden encontrar en la sección educacion bajo el
              nombre DITER.
            </p>
          </f7-card-content>
        </f7-card>

        <f7-card class="demo-card-header-pic">
          <f7-card-header
            class="no-border"
            valign="bottom"
            style="
              background-image: url(https://cdn.framework7.io/placeholder/nature-1000x600-3.jpg);
            "
            ><div class="alf-overlay">Servicio de transición de pacientes de reumatologia
            pediátrica</div></f7-card-header
          >
          <f7-card-content>
            <p class="date">Publicado el 20 de Octubre, 2020</p>
            <p>
              Aquí encontrará el listado y detalles de todos los pacientes que
              hayan hecho la transición de reumatología pediátrica a
              reumatología mediante nuestra aplicación y lo hayan seleccionado a
              usted para seguir con su tratamiento.
            </p>
          </f7-card-content>
          <f7-card-footer>
            <f7-link href="/catalog/">Ver listado de transiciones</f7-link>
          </f7-card-footer>
        </f7-card>

      </f7-block>
    </div>
  </f7-page>
</template>

<script>
import cookies from "vue-cookies";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["userProfile", "performingRequest"]),
  },
  data: function () {
    return {};
  },
  mounted() {},
  methods: {
    logout() {
      this.$cookies.remove("user");
      this.$f7.views.main.router.navigate("/login/");
    },
  },
};
</script>
