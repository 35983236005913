import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.scss';
import App from '../components/app.vue';
import { store } from './store.js';
import VueCookies from 'vue-cookies';

Vue.use(VueAxios, axios);
Vue.use(VueCookies);

Framework7.use(Framework7Vue);
// Init App
new Vue({
  el: '#app',
  store,
  render: (h) => h(App),

  // Register App Component
  components: {
    app: App
  },
});