<template>
  <f7-app :params="f7params" theme-dark>

  <!-- Your main view/tab, should have "view-main" class. It also has "tab-active" class -->
  <f7-view id="view-home" main url="/"></f7-view>
  </f7-app>
</template>
<script>

  import routes from '../js/routes.js';
  import { mapState } from "vuex";

  export default {
     computed: {
			...mapState([
				"userProfile",
        "performingRequest",
			]),
		},
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Reumapp Transition', // App name
          theme: 'auto', // Automatic theme detection

          // App root data
          data: function () {
            return {

              // Demo products for Catalog section
              products: [
                {
                  id: '1',
                  title: 'Apple iPhone 8',
                  description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi tempora similique reiciendis, error nesciunt vero, blanditiis pariatur dolor, minima sed sapiente rerum, dolorem corrupti hic modi praesentium unde saepe perspiciatis.'
                },
                {
                  id: '2',
                  title: 'Apple iPhone 8 Plus',
                  description: 'Velit odit autem modi saepe ratione totam minus, aperiam, labore quia provident temporibus quasi est ut aliquid blanditiis beatae suscipit odio vel! Nostrum porro sunt sint eveniet maiores, dolorem itaque!'
                },
                {
                  id: '3',
                  title: 'Apple iPhone X',
                  description: 'Expedita sequi perferendis quod illum pariatur aliquam, alias laboriosam! Vero blanditiis placeat, mollitia necessitatibus reprehenderit. Labore dolores amet quos, accusamus earum asperiores officiis assumenda optio architecto quia neque, quae eum.'
                },
              ]
            };
          },

          // App routes
          routes: routes,
          // Register service worker
          serviceWorker: {
            path: '/service-worker.js',
          },
        },
        // Login screen data
        username: '',
        password: '',
      }
    },
    methods: {
      alertLoginData() {
        this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password, () => {
          this.$f7.loginScreen.close();
        });
      },
      handler: function handler(event) {
          console.log('creando evento refresh');
      }
    },
    mounted() {
      this.$f7ready((f7) => {
      });
    },
    created() {
      window.addEventListener('beforeunload', this.handler)
    },
  }
</script>